import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { UilBars, UilTimes, UilClock, UilCalling, UilCalendarAlt } from '@iconscout/react-unicons';
import Modal from 'react-modal';
import CleaningForm from '../components/CleaningForm'; // Assuming the path for CleaningForm component
import Logo from '../assets/Images/logo.png'; // Update the logo path as necessary

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const node = useRef();

  // Toggle modal state
  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  // Handle outside click to close menu
  const handleClickOutside = (e) => {
    if (node.current && !node.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  const handleMenuItemClick = () => {
    setIsOpen(false); // Close the menu when a menu item is clicked
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="bg-[#003366] md:px-4 md:py-3" ref={node}>
  <div className="flex flex-col">
    <div className="flex items-center justify-between px-4 py-3 sm:p-0">
      <Link to="/" className="flex items-center">
        <img className="h-20 w-auto sm:h-24 transition-transform transform hover:scale-105" src={Logo} alt="Fabiana Fabulous Cleaning Service LLC" />
        <div className="hover:opacity-80">
          <h1 className="text-gold font-playfair text-3xl">Fabiana Fabulous</h1>
          <h2 className="text-gold font-playfair text-xl">Cleaning Services LLC</h2>
        </div>
      </Link>
      <button onClick={toggleMenu} className="lg:hidden xl:hidden">
        {isOpen ? (
          <UilTimes className="text-4xl ml-2 mt-2 text-[#d99d49]" />
        ) : (
          <UilBars className="text-4xl ml-2 mt-2 text-[#d99d49]" />
        )}
      </button>
      <div className="hidden md:flex items-center gap-0">
        <UilClock size="35" />
        <div className="flex flex-col">
          <span className="mt-1 block text-[#057dcd] font-semibold text-sm sm:text-base">OPEN HOURS</span>
          <span className="mt-1 block text-white font-semibold text-sm sm:text-base">Open from 7:00am to 6:00pm</span>
        </div>
        <UilCalling size="30" className="call-animate" />
        <div className="flex flex-col">
          <span className="mt-1 block text-[#057dcd] font-semibold text-sm sm:text-base transition-transform transform hover:scale-105">CALL US TODAY!</span>
          <a href="tel:425-829-6092" className="mt-1 block text-white hover:text-[#d4af37] font-semibold text-sm sm:text-base transition-transform transform hover:scale-105">(425) 829-6092</a>
        </div>
      </div>
    </div>
    <hr className="border-gold" />
    <nav className={`px-2 pt-2 pb-4 sm:flex sm:p-3 sm:ml-14 ${isOpen ? 'block bg-gradient-to-r from-gold to-blue-500' : 'hidden'} transition ease-in-out duration-300`}>
      <div className="flex flex-col sm:flex-row justify-center items-end w-full md:justify-center">
        <Link className="block px-2 py-1 text-white text-xl font-semibold rounded   hover:text-[#d4af37] transition duration-300" onClick={handleMenuItemClick} to="/">Home</Link>
        <Link className="mt-1 block px-2 py-1 text-white text-xl font-semibold rounded   hover:text-[#d4af37] sm:mt-0 sm:ml-2 transition duration-300" onClick={handleMenuItemClick} to="/services">Services</Link>
        <Link className="mt-1 block px-2 py-1 text-white text-xl font-semibold rounded   hover:text-[#d4af37] sm:mt-0 sm:ml-2 transition duration-300" onClick={handleMenuItemClick} to="/about">About</Link>
        <Link className="mt-1 block px-2 py-1 text-white text-xl font-semibold rounded   hover:text-[#d4af37] sm:mt-0 sm:ml-2 transition duration-300" onClick={handleMenuItemClick} to="/contact">Contact</Link>
        <Link className="mt-1 block px-2 py-1 text-white text-xl font-semibold rounded   hover:text-[#d4af37] sm:mt-0 sm:ml-2 transition duration-300" onClick={handleMenuItemClick} to="/gallery">Gallery</Link>
        <div className="mt-1 block px-2 py-1">
          <button onClick={toggleModal} className=" flex items-center text-[#ffff] bloom-text transition duration-300 hover:text-[#d4af37]">
            <UilCalendarAlt className="mr-2 button-animate " size="20" /> Quick Estimate!
          </button>
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={toggleModal}
                contentLabel="Cleaning Form"
                style={{
                  overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    transition: 'opacity 0.5s',
                  },
                  content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    maxWidth: '500px',
                    height: '90%',
                    maxHeight: '500px',
                    padding: '2rem',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 1)',
                    border: '1px solid #ccc',
                    overflow: 'hidden',
                    background: '#fff',
                    color: '#000',
                    zIndex: '1000',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    gap: '1rem',
                    fontFamily: 'sans-serif',
                    fontSize: '1rem',
                    fontWeight: '400',
                    lineHeight: '1.5',
                    letterSpacing: '0.00938em',
                    textTransform: 'none',
                  },
                }}
              >
                <CleaningForm />
                <button
                  onClick={toggleModal}
                  style={{
                    position: 'absolute',
                    top: '1rem',
                    right: '1rem',
                    background: 'transparent',
                    border: 'none',
                    color: '#000',
                    fontSize: '1.5rem',
                    cursor: 'pointer',
                  }}
                >
                  &times;
                </button>
              </Modal>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default NavBar;