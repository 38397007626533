import React from 'react'
import { motion } from 'framer-motion'
import Yelp from '../assets/Icons/yelp-logo.png'
import { UilFacebookF, UilInstagram } from '@iconscout/react-unicons'

const FooterPage = () => {
    return (
        <div>
            <div className="flex items-center justify-center mt-10">
                <hr className="w-1/4 border-1 border-[#50e0fa]" />
                <span className="px-2 text-[#050505] font-semibold">CONNECT WITH US</span>
                <hr className="w-1/4 border-1 border-[#50e0fa]" />
            </div>
            <div className='flex items-center justify-center mt-3   gap-8'>
                <a href='https://www.facebook.com/Fabulouscleaner/' target='_blank' rel='noopener noreferrer'>
                    <motion.p
                        whileHover={{ scale: 2.1 }}
                        whileTap={{ scale: 0.9 }}
                        title='Social-Media Facebook'
                        className=''> <UilFacebookF color="#1877F2" /></motion.p> {/* Facebook's official color */}
                </a>
                <a href='https://www.yelp.com/biz/fabiana-fabulous-cleaning-services-redmond-4' target='_blank' rel='noopener noreferrer'>
                    <motion.p
                        whileHover={{ scale: 1.6 }}
                        whileTap={{ scale: 0.8 }}
                        title='Social-Media Yelp'
                        className=''> <img src={Yelp} className='w-10 h-10' alt='yelp' /></motion.p> {/* Yelp logo doesn't need a color prop */}
                </a>
                <a href=' https://www.instagram.com/fabianallc/' target='_blank' rel='noopener noreferrer'>
                    <motion.p
                        whileHover={{ scale: 2.1 }}
                        whileTap={{ scale: 0.9 }}
                        title='Social-Media Instagram'
                        className=' '> <UilInstagram color="#C13584" /></motion.p> {/* Instagram's official color */}
                </a>
            </div>
            <div className="footer">
                <div>
                    <h6 className=" text-center text-[#080808]">ⓒ Copyright 2024 </h6>
                    <h6 className=" text-[#080808] font-semi-bold">Fabiana Fabulous Cleaning Services LLC</h6>
                </div>

                <div>
                    <h6 className=" text-center text-[#080808] mt-2">Created by: </h6>
                    <a href='https://www.linkedin.com/in/victor-villagrana/' target='_blank' rel='noopener noreferrer' title="Contact Me">
                        <h6 className=" text-[#080808] font-thin hover:text-[#d4af37]">@Victor Software Developer</h6>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default FooterPage