// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getStorage} from "firebase/storage";
// import { getFirestore } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyDg-e4dcMfqGdZwnYsipCXmwHvWxfbsqWc",
  authDomain: "fabicleaners-28c5e.firebaseapp.com",
  databaseURL: "https://fabicleaners-28c5e-default-rtdb.firebaseio.com",
  projectId: "fabicleaners-28c5e",
  storageBucket: "fabicleaners-28c5e.appspot.com",
  messagingSenderId: "868662195683",
  appId: "1:868662195683:web:90fbf6c86f60b3a9831cd6",
  measurementId: "G-9RSLBPQY7C"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const db = getFirestore(app);
const storage = getStorage(app);
export {storage};